.menu{
    left:0;
    width:auto;
    position: relative;
}

.mb-3{
    justify-content: center;
}

.nav-link{
    color: orangered !important;
    font-weight: bold;
    padding: 16px 48px 16px 48px;
    -webkit-touch-callout: none; 
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.portfolio {
    right:0;
    margin-left: 23%;
    position: relative;
    padding: 80px 0;
    /*background-color: white; */
  }
  .portfolio h2 {
    font-size: 45px;
    font-weight: 700;
    text-align: center;
  }
  .portfolio p {
    color: darkgray;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px auto 30px auto;
    text-align: center;
    width: 56%;
  }
  .portfolio .nav.nav-pills {
    width: 72%;
    margin: 0 auto;
    border-radius: 50px;
    background-color: rgb(255 255 255 / 10%);
    overflow: hidden;
  }
  .portfolio .nav.nav-pills .nav-item {
    width: 25%;
  }
  .portfolio .nav.nav-pills .nav-link {
    background-color: transparent;
    border-radius: 0;
    padding: 17px 0;
    color: #fff;
    width: 100%;
    font-size: 17px;
    letter-spacing: 0.8px;
    font-weight: 500;
    position: relative;
    transition: 0.3s ease-in-out;
    text-align: center;
    z-index: 0;
  }
  .portfolio .nav.nav-pills .nav-link::before {
      content: "";
      position: absolute;
      width: 0;
      height: 100%;
      background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
      top: 0;
      left: 0;
      z-index: -1;
      transition: 0.3s ease-in-out;
  }
  .portfolio .nav.nav-pills .nav-link.active::before {
      width: 100% !important;
  }
  .portfolio .nav.nav-pills .nav-link.active {
      border: 1px solid rgba(255, 255, 255, 1);
  }
  .nav-link#portfolio-tabs-tab-tab_1 {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 55px 0px 0px 55px;
  }
  .nav-link#portfolio-tabs-tab-tab_2 {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
  .nav-link#portfolio-tabs-tab-tab_3 {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
  .nav-link#portfolio-tabs-tab-tab_4 {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 0 55px 55px 0;
  }
  .skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
  }
  @keyframes animation {
    0% {
      background-color: hsl(200, 20%, 70%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }
  .portfolio-imgbx {
    width: 250px;
    height: 250px;
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 10%;
    margin-left: 10%;
  }
  .portfolio-imgbx::before {
    content: "";
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    opacity: 0.85;
    position: absolute;
    width: 100%;
    height: 0;
    transition: 0.4s ease-in-out;
  }
  .portfolio-imgbx:hover::before {
    height: 100%;
  }
  .tab-details {
    display: flex;
    overflow: hidden; 
    flex-wrap: wrap;
  }
  .portfolio-tx {
    position: absolute;
    text-align: center;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s ease-in-out;
    opacity: 0;
    width: 100%;
  }
  .portfolio-img-prop {
    width:100%;
    height:100%;
    object-fit: cover;
  }
  .portfolio-imgbx:hover .portfolio-tx {
    top: 50%;
    opacity: 1;
    color: white;
  }
  .portfolio-title {
    white-space: nowrap;
  }
  .portfolio-tx h4 {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1.1em;
  }
  .portfolio-tx span {
    font-style: italic;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.8px;
  }
  .background-img-right {
    top: 20%;
    position: absolute;
    bottom: 0;
    width: 35%;
    right: 0;
    z-index: -4;
  }

  .subview-image{
    position: fixed;
    z-index: 3;
    top: 10%;
    left: 30%;
    right: 30%;
    max-width: 40%;
    max-height: 100%;

  }

  .subview-close{
    position: fixed;
    color: grey;
    opacity: 90%;
    z-index: 4;
    width: 50px;
    height: 50px;
    top: 10%;
    left: 30%;
  }

  .subview-pageclose{
    position: absolute;
    color: grey;
    opacity: 90%;
    z-index: 4;
    width: 50px;
    height: 50px;
    top: 10%;
    left: 5%;
  }

  .subview-com{
    border: lightcyan;
    border-width: 0.5cm;
  }
  .subview-pageDetail{
    position: absolute;
    z-index: 3;
    top: 10%;
    left: 5%;
    background-color: lightcyan;
    border: grey;
    padding: 50px;
  }

  .subview-title{
    text-align: center;
  }

  .subview-text{
    white-space:nowrap;
  }

  .subview-images{
    padding: 10px;
    display: flex;
    overflow: hidden; 
    flex-wrap: wrap;
  }