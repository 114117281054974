:root{
    --font-color: #fff;
}

.biography {
    margin-left: 5%;
    margin-right: 5%;
}

.biography legend{

    margin-top: 20%;
    margin-bottom: 10%;
    /*font-family: 'Courier New', Courier, monospace;*/
    text-align: center;
    font-size: 45px;
    color: var(--font-color);
}

.biography p{
    /*font-family: 'Courier New', Courier, monospace;*/
    font-size: 30px;
    text-align: justify;
    line-height: 2;
    color:  var(--font-color);
}