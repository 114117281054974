:root{
    --font-color: #fff;
}

.footer {
    padding: 0 0 10% 0;
    margin-left: 25%;
    margin-right: 25%;
    font-size: larger;
    font-weight: bolder;
    color: var(--font-color);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center !important;
  }

.footer p {
    font-weight: bold;
    text-align: center;
    font-size: 14px;
    color: var(--font-color);
    font-weight: bold;
    letter-spacing: 0.5px;
    margin-top: 20%;
    margin-bottom: 10%;
  }