.top-btn-position{
    height: 5%;
    width: 5%;
    position: fixed;
    bottom: 15px;
    right: 1%;
    z-index: 5;
}

.top-btn-style:hover {
    animation: none;
    background-color: #fff;
    color: #333;
}