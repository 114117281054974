:root{
    --fontcolor:black;
}

.blogs{
    right:0;
    margin-left: 23%;
    position: relative;
    background: linear-gradient(90.21deg, #ad4a86 -5.91%, #5c45c4 111.58%);
    font-family: 'Centra', sans-serif;
    -webkit-touch-callout: none; 
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /*background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);*/
    /*background-color: white;*/

    color: var(--fontcolor) !important;
}