:root{
  --fontcolor:black;
}

.sidebar {
    margin: 0;
    padding: 0;
    width: 23%;
    background-color: #f1f1f1;
    position: fixed;
    height: 100%;
    overflow: auto;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    list-style-type: none;
  }

  .avatar-image{
    width: 50%;
  }

  .name{
    font-size: large;
    font-weight: bold;
    text-align: center;
    text-align: center;
    font-family: 'Courier New', Courier, monospace;
    list-style-type: none;
  }

  

  .detail{
    font-size: small;
    text-align: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 100;
    list-style-type: none;
    padding-bottom: 30px;
  }

  .socialBar{
    position: fixed;
    bottom: 0;
  }
  
  .sidebar a {
    font-family: Arial, Helvetica, sans-serif;
    color: var(--fontcolor);
    padding: 16px;
    text-decoration: none;
    list-style-type: none;
    min-width: 60%;
    display: block;
    border: none;
  }



  .sidebar a.portfolio_page{
    font-family: Arial, Helvetica, sans-serif;
    color: var(--fontcolor);
    padding: 16px;
    text-decoration: none;
    list-style-type: none;
    min-width: 60%;
  }

  .sidebar a.active {
    background-color: #04AA6D;
    color: white;
  }
  
  .sidebar a:hover:not(.active) {
    background-color: #555;
    opacity: 0.5;
    color: white;
  }

  .sidebar button {
    display: block;
    color: var(--fontcolor);
    padding: 16px;
    padding-right: 100%;
    border: none;
    text-decoration: none;
  }
   
  .sidebar button.active {
    background-color: #04AA6D;
    color: white;
  }
  
  .sidebar button:hover:not(.active) {
    background-color: #555;
    opacity: 0.5;
    color: white;
  }

  /* Menu Nav */
.nav .menu-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
}

.nav .menu-nav::after {
  content: '';
  width: 1px;
  height: 2rem;
  background: rgb(0,0,0);
}
  
  div.content {
    margin-left: 200px;
    padding: 1px 16px;
    height: 1000px;
  }
  
  @media screen and (max-width: 700px) {
    .sidebar {
      width: 100%;
      height: auto;
      position: relative;
    }
    .sidebar a {float: left;}
    .sidebar button {float: left;}
    div.content {margin-left: 0;}
  }
  
  @media screen and (max-width: 400px) {
    .sidebar a {
      text-align: center;
      float: none;
    }
    .sidebar button {
        text-align: center;
        float: none;
    }
  }

  @keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }