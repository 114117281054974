:root{
    --font-color: #fff;
    --border-color: rgba(255, 255, 255, 0.5);
    --re-font-color:#fff;
    --message-res:#fff;
}
 
 .contact {
    /*background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);*/
    margin-left: 25%;
    margin-right: 25%;
    padding: 10% 0 20% 0;
    width: 100% !important;
  }
  .contact img {
    width: 92%;
  }
  .contact h2 {
    text-align: center;
    color: var(--font-color);
    font-size: 45px;
    font-weight: 700;
    margin-top: 20%;
    margin-bottom: 10%;
  }
  .contact form input, .contact form textarea {
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid var(--font-color);
    border-radius: 20px;
    color: var(--font-color);
    margin: 0 0 8px 0;
    padding: 18px 26px;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.8px;
    transition: 0.3s ease-in-out;
  }
  .contact form input:focus, .contact form textarea:focus {
    background: rgba(255, 255, 255, 1);
    color: #121212;
  }
  .contact form input::placeholder, .contact form textarea::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: var(--font-color);
  }
  .contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
    color: var(--font-color);
    opacity: 0.8;
  }
  .contact form button {
    font-weight: 700;
    color: var(--re-font-color);
    background-color: #121212;
    padding: 14px 48px;
    font-size: 18px;
    margin-top: 25px;
    border-radius: 0;
    position: relative;
    transition: 0.3s ease-in-out;
  }
  .contact form button span {
    z-index: 1;
    position: relative;
  }
  .contact form button:hover {
    color: #121212;
  }
  .contact form button::before {
    content: "";
    background: var(--re-font-color);
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    transition: 0.3s ease-in-out;
  }
  .contact form button:hover::before {
    width: 100%;
  }
  .contact p.success{
    color: var(--message-res) !important;
  }