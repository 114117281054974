body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
  --fontcolor:black;
}

.sidebar {
    margin: 0;
    padding: 0;
    width: 23%;
    background-color: #f1f1f1;
    position: fixed;
    height: 100%;
    overflow: auto;
    -webkit-animation: fadeInAnimation ease 3s;
            animation: fadeInAnimation ease 3s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    list-style-type: none;
  }

  .avatar-image{
    width: 50%;
  }

  .name{
    font-size: large;
    font-weight: bold;
    text-align: center;
    text-align: center;
    font-family: 'Courier New', Courier, monospace;
    list-style-type: none;
  }

  

  .detail{
    font-size: small;
    text-align: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 100;
    list-style-type: none;
    padding-bottom: 30px;
  }

  .socialBar{
    position: fixed;
    bottom: 0;
  }
  
  .sidebar a {
    font-family: Arial, Helvetica, sans-serif;
    color: black;
    color: var(--fontcolor);
    padding: 16px;
    text-decoration: none;
    list-style-type: none;
    min-width: 60%;
    display: block;
    border: none;
  }



  .sidebar a.portfolio_page{
    font-family: Arial, Helvetica, sans-serif;
    color: black;
    color: var(--fontcolor);
    padding: 16px;
    text-decoration: none;
    list-style-type: none;
    min-width: 60%;
  }

  .sidebar a.active {
    background-color: #04AA6D;
    color: white;
  }
  
  .sidebar a:hover:not(.active) {
    background-color: #555;
    opacity: 0.5;
    color: white;
  }

  .sidebar button {
    display: block;
    color: black;
    color: var(--fontcolor);
    padding: 16px;
    padding-right: 100%;
    border: none;
    text-decoration: none;
  }
   
  .sidebar button.active {
    background-color: #04AA6D;
    color: white;
  }
  
  .sidebar button:hover:not(.active) {
    background-color: #555;
    opacity: 0.5;
    color: white;
  }

  /* Menu Nav */
.nav .menu-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 0.8rem;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
}

.nav .menu-nav::after {
  content: '';
  width: 1px;
  height: 2rem;
  background: rgb(0,0,0);
}
  
  div.content {
    margin-left: 200px;
    padding: 1px 16px;
    height: 1000px;
  }
  
  @media screen and (max-width: 700px) {
    .sidebar {
      width: 100%;
      height: auto;
      position: relative;
    }
    .sidebar a {float: left;}
    .sidebar button {float: left;}
    div.content {margin-left: 0;}
  }
  
  @media screen and (max-width: 400px) {
    .sidebar a {
      text-align: center;
      float: none;
    }
    .sidebar button {
        text-align: center;
        float: none;
    }
  }

  @-webkit-keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }

  @keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }
.avatar {
    vertical-align: middle;
    height: auto;
    margin-left: 50%;
    margin-top: 10%;
    border-radius: 50%;
    border: 5px double rgb(0, 0, 0, 0.1)
}


.socials{
    flex-direction: column;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
}
:root{
    --font-color: #fff;
}

.biography {
    margin-left: 5%;
    margin-right: 5%;
}

.biography legend{

    margin-top: 20%;
    margin-bottom: 10%;
    /*font-family: 'Courier New', Courier, monospace;*/
    text-align: center;
    font-size: 45px;
    color: #fff;
    color: var(--font-color);
}

.biography p{
    /*font-family: 'Courier New', Courier, monospace;*/
    font-size: 30px;
    text-align: justify;
    line-height: 2;
    color:  #fff;
    color:  var(--font-color);
}
:root{
    --font-color: #fff;
    --border-color: rgba(255, 255, 255, 0.5);
    --re-font-color:#fff;
    --message-res:#fff;
}
 
 .contact {
    /*background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);*/
    margin-left: 25%;
    margin-right: 25%;
    padding: 10% 0 20% 0;
    width: 100% !important;
  }
  .contact img {
    width: 92%;
  }
  .contact h2 {
    text-align: center;
    color: #fff;
    color: var(--font-color);
    font-size: 45px;
    font-weight: 700;
    margin-top: 20%;
    margin-bottom: 10%;
  }
  .contact form input, .contact form textarea {
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #fff;
    border: 1px solid var(--font-color);
    border-radius: 20px;
    color: #fff;
    color: var(--font-color);
    margin: 0 0 8px 0;
    padding: 18px 26px;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.8px;
    transition: 0.3s ease-in-out;
  }
  .contact form input:focus, .contact form textarea:focus {
    background: rgba(255, 255, 255, 1);
    color: #121212;
  }
  .contact form input::-webkit-input-placeholder, .contact form textarea::-webkit-input-placeholder {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    color: var(--font-color);
  }
  .contact form input::placeholder, .contact form textarea::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    color: var(--font-color);
  }
  .contact form input:focus::-webkit-input-placeholder, .contact form textarea:focus::-webkit-input-placeholder {
    color: #fff;
    color: var(--font-color);
    opacity: 0.8;
  }
  .contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
    color: #fff;
    color: var(--font-color);
    opacity: 0.8;
  }
  .contact form button {
    font-weight: 700;
    color: #fff;
    color: var(--re-font-color);
    background-color: #121212;
    padding: 14px 48px;
    font-size: 18px;
    margin-top: 25px;
    border-radius: 0;
    position: relative;
    transition: 0.3s ease-in-out;
  }
  .contact form button span {
    z-index: 1;
    position: relative;
  }
  .contact form button:hover {
    color: #121212;
  }
  .contact form button::before {
    content: "";
    background: #fff;
    background: var(--re-font-color);
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    transition: 0.3s ease-in-out;
  }
  .contact form button:hover::before {
    width: 100%;
  }
  .contact p.success{
    color: #fff !important;
    color: var(--message-res) !important;
  }
.background-img{
    margin-top: 0;
    max-width:100%;
    max-height:100%;
}
:root{
    --font-color: #fff;
}

.footer {
    padding: 0 0 10% 0;
    margin-left: 25%;
    margin-right: 25%;
    font-size: larger;
    font-weight: bolder;
    color: #fff;
    color: var(--font-color);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center !important;
  }

.footer p {
    font-weight: bold;
    text-align: center;
    font-size: 14px;
    color: #fff;
    color: var(--font-color);
    font-weight: bold;
    letter-spacing: 0.5px;
    margin-top: 20%;
    margin-bottom: 10%;
  }
.top-btn-position{
    height: 5%;
    width: 5%;
    position: fixed;
    bottom: 15px;
    right: 1%;
    z-index: 5;
}

.top-btn-style:hover {
    -webkit-animation: none;
            animation: none;
    background-color: #fff;
    color: #333;
}
:root{
    --fontcolor:black;
}

.blogs{
    right:0;
    margin-left: 23%;
    position: relative;
    background: linear-gradient(90.21deg, #ad4a86 -5.91%, #5c45c4 111.58%);
    font-family: 'Centra', sans-serif;
    -webkit-touch-callout: none; 
    -webkit-user-select: none;
    user-select: none;
    /*background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);*/
    /*background-color: white;*/

    color: black !important;

    color: var(--fontcolor) !important;
}
.menu{
    left:0;
    width:auto;
    position: relative;
}

.mb-3{
    justify-content: center;
}

.nav-link{
    color: orangered !important;
    font-weight: bold;
    padding: 16px 48px 16px 48px;
    -webkit-touch-callout: none; 
    -webkit-user-select: none;
    user-select: none;
}

.portfolio {
    right:0;
    margin-left: 23%;
    position: relative;
    padding: 80px 0;
    /*background-color: white; */
  }
  .portfolio h2 {
    font-size: 45px;
    font-weight: 700;
    text-align: center;
  }
  .portfolio p {
    color: darkgray;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px auto 30px auto;
    text-align: center;
    width: 56%;
  }
  .portfolio .nav.nav-pills {
    width: 72%;
    margin: 0 auto;
    border-radius: 50px;
    background-color: rgb(255 255 255 / 10%);
    overflow: hidden;
  }
  .portfolio .nav.nav-pills .nav-item {
    width: 25%;
  }
  .portfolio .nav.nav-pills .nav-link {
    background-color: transparent;
    border-radius: 0;
    padding: 17px 0;
    color: #fff;
    width: 100%;
    font-size: 17px;
    letter-spacing: 0.8px;
    font-weight: 500;
    position: relative;
    transition: 0.3s ease-in-out;
    text-align: center;
    z-index: 0;
  }
  .portfolio .nav.nav-pills .nav-link::before {
      content: "";
      position: absolute;
      width: 0;
      height: 100%;
      background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
      top: 0;
      left: 0;
      z-index: -1;
      transition: 0.3s ease-in-out;
  }
  .portfolio .nav.nav-pills .nav-link.active::before {
      width: 100% !important;
  }
  .portfolio .nav.nav-pills .nav-link.active {
      border: 1px solid rgba(255, 255, 255, 1);
  }
  .nav-link#portfolio-tabs-tab-tab_1 {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 55px 0px 0px 55px;
  }
  .nav-link#portfolio-tabs-tab-tab_2 {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
  .nav-link#portfolio-tabs-tab-tab_3 {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
  .nav-link#portfolio-tabs-tab-tab_4 {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 0 55px 55px 0;
  }
  .skeleton {
    -webkit-animation: skeleton-loading 1s linear infinite alternate;
            animation: skeleton-loading 1s linear infinite alternate;
  }
  @-webkit-keyframes animation {
    0% {
      background-color: hsl(200, 20%, 70%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }
  @keyframes animation {
    0% {
      background-color: hsl(200, 20%, 70%);
    }
    100% {
      background-color: hsl(200, 20%, 95%);
    }
  }
  .portfolio-imgbx {
    width: 250px;
    height: 250px;
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 10%;
    margin-left: 10%;
  }
  .portfolio-imgbx::before {
    content: "";
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    opacity: 0.85;
    position: absolute;
    width: 100%;
    height: 0;
    transition: 0.4s ease-in-out;
  }
  .portfolio-imgbx:hover::before {
    height: 100%;
  }
  .tab-details {
    display: flex;
    overflow: hidden; 
    flex-wrap: wrap;
  }
  .portfolio-tx {
    position: absolute;
    text-align: center;
    top: 65%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    transition: 0.5s ease-in-out;
    opacity: 0;
    width: 100%;
  }
  .portfolio-img-prop {
    width:100%;
    height:100%;
    object-fit: cover;
  }
  .portfolio-imgbx:hover .portfolio-tx {
    top: 50%;
    opacity: 1;
    color: white;
  }
  .portfolio-title {
    white-space: nowrap;
  }
  .portfolio-tx h4 {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1.1em;
  }
  .portfolio-tx span {
    font-style: italic;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.8px;
  }
  .background-img-right {
    top: 20%;
    position: absolute;
    bottom: 0;
    width: 35%;
    right: 0;
    z-index: -4;
  }

  .subview-image{
    position: fixed;
    z-index: 3;
    top: 10%;
    left: 30%;
    right: 30%;
    max-width: 40%;
    max-height: 100%;

  }

  .subview-close{
    position: fixed;
    color: grey;
    opacity: 90%;
    z-index: 4;
    width: 50px;
    height: 50px;
    top: 10%;
    left: 30%;
  }

  .subview-pageclose{
    position: absolute;
    color: grey;
    opacity: 90%;
    z-index: 4;
    width: 50px;
    height: 50px;
    top: 10%;
    left: 5%;
  }

  .subview-com{
    border: lightcyan;
    border-width: 0.5cm;
  }
  .subview-pageDetail{
    position: absolute;
    z-index: 3;
    top: 10%;
    left: 5%;
    background-color: lightcyan;
    border: grey;
    padding: 50px;
  }

  .subview-title{
    text-align: center;
  }

  .subview-text{
    white-space:nowrap;
  }

  .subview-images{
    padding: 10px;
    display: flex;
    overflow: hidden; 
    flex-wrap: wrap;
  }

.main-screen{
    background-image: './src/images/1.jpg';
}

.biography{
    margin-top:50px;
    margin-bottom:60px;
}

/* Trial*/

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  font-family: 'Centra', sans-serif !important;
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}
